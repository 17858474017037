import { environmentConfig } from "@src/appV2/environment";
import { logApiFailureEvent } from "@src/lib/analytics";
import request from "superagent";

import { getAuthHeader } from "../superagent";

enum LogType {
  INFO = "INFO",
  ERROR = "ERROR",
}

enum LogArea {
  SERVER = "SERVER",
}

enum FeatureName {
  OPEN_SHIFTS_API = "OPEN_SHIFTS_API",
}

/**
 * @deprecated
 * Use logEvent from @src/appV2/lib/analytics
 */
const hcpAppLogger = async (
  data: any,
  { tryCount = 3, tryTimeout = 60000 } = {}
): Promise<void> => {
  if (tryCount < 1) {
    return;
  }
  try {
    await request
      .post(`${environmentConfig.REACT_APP_BASE_API_URL}/hcpAppLogs`)
      .set(await getAuthHeader())
      .send(data);
  } catch (error) {
    setTimeout(() => {
      hcpAppLogger(data, { tryCount: tryCount - 1, tryTimeout });
    }, tryTimeout);

    logApiFailureEvent(error);
  }
};

export { hcpAppLogger, LogType, LogArea, FeatureName };
